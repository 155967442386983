interface GbTrackerInstance {
  sendViewEvent: (event: { type: string; name: string }) => void;
  sendAddToCartEvent: (eventData: any) => void;
  autoSetVisitor: (LoginID?: string) => void;
  sendAutoSearchEvent: (eventData: any) => void;
  sendViewProductEvent: (eventData: any) => void;
  sendOrderEvent: (eventData: any) => void;
  setVisitor: (visitorId: string, sessionId: string) => void;
  getSessionId: () => string;
  getVisitorId: () => string;
  sendHomePageViewEvent: (eventData: any) => void;
}

interface GbTrackerConstructor {
  new (customerId: string, area: string): GbTrackerInstance;
  new (customerId: string, area: string, LoginID: string): GbTrackerInstance;
}

let gbTrackerInstance: GbTrackerInstance | null = null;
let gbTrackerInstance1: GbTrackerInstance | null = null;

export function initializeGbTracker(customerId: string, area: string): void {
  if (typeof window !== 'undefined' && window.GbTracker && !gbTrackerInstance) {
    gbTrackerInstance = new window.GbTracker(customerId, area);
    gbTrackerInstance?.autoSetVisitor();
    gbTrackerInstance1 = new window.GbTracker(customerId, area);
  }
}

export function trackEvent(eventName: string, eventData: Record<string, any>, cid?: string): void {
  if (!gbTrackerInstance) {
    console.warn('GbTracker not initialized. Call initializeGbTracker first.');
    return;
  }

  if (
    eventName === 'addToCart' ||
    eventName === 'order' ||
    eventName === 'viewProduct' ||
    eventName === 'search' ||
    eventName === 'homepage'
  ) {
    if (cid) {
      const cidInString = cid?.toString();
      gbTrackerInstance1?.autoSetVisitor(cidInString);
    } else {
      gbTrackerInstance?.autoSetVisitor();
    }
  }

  switch (eventName) {
    case 'view':
      cid
        ? gbTrackerInstance1?.sendViewEvent({ type: 'View', name: eventData.name })
        : gbTrackerInstance?.sendViewEvent({ type: 'View', name: eventData.name });
      break;
    case 'addToCart':
      cid ? gbTrackerInstance1?.sendAddToCartEvent(eventData) : gbTrackerInstance?.sendAddToCartEvent(eventData);
      break;
    case 'search':
      cid ? gbTrackerInstance1?.sendAutoSearchEvent(eventData) : gbTrackerInstance?.sendAutoSearchEvent(eventData);
      break;
    case 'viewProduct':
      cid ? gbTrackerInstance1?.sendViewProductEvent(eventData) : gbTrackerInstance?.sendViewProductEvent(eventData);
      break;
    case 'order':
      cid ? gbTrackerInstance1?.sendOrderEvent(eventData) : gbTrackerInstance?.sendOrderEvent(eventData);
      break;
    case 'homepage':
      cid ? gbTrackerInstance1?.sendHomePageViewEvent(eventData) : gbTrackerInstance?.sendHomePageViewEvent(eventData);
      break;
    default:
      console.warn(`Event type '${eventName}' is not supported.`);
  }
}

export function useGbTracker(): {
  trackEvent: (eventName: string, eventData: Record<string, any>, cid?: string) => void;
} {
  return { trackEvent };
}
